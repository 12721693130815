<!-- Start Banner Area -->
<div class="banner-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="banner-content">
                    <span>Welcome to PD Automotive</span>
                    <h1>We keep you moving with precision, trust, and unmatched care</h1>
                    <p>Since the year 2021, we combine expert knowledge and top-tier service to keep your vehicle running smoothly and reliably.</p>
                    <p> Trusted by the Palmerston North community for all your automotive needs</p>
                    <div class="banner-btn">
                        <a routerLink="/services" class="get-btn">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 pr-0">
                <div class="banner-img">
                    <img src="assets/img/home-two.jpg" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Banner Area End -->

<!-- Start Brand Area -->
<div class="brand-area">
    <div class="container">
        <div class="brand-slider owl-carousel owl-theme">
            <div class="brand-item">
                <img src="assets/img/brand/toyota.png" alt="Images">
            </div>

            <div class="brand-item">
                <img src="assets/img/brand/mitsubishi.png" alt="Images">
            </div>

            <div class="brand-item">
                <img src="assets/img/brand/suzuki.png" alt="Images">
            </div>

            <div class="brand-item">
                <img src="assets/img/brand/honda.png" alt="Images">
            </div>

            <div class="brand-item">
                <img src="assets/img/brand/kia.png" alt="Images">
            </div>

            <div class="brand-item">
                <img src="assets/img/brand/hyundai.png" alt="Images">
            </div>

            <div class="brand-item">
                <img src="assets/img/brand/bmw.png" alt="Images">
            </div>
            <div class="brand-item">
                <img src="assets/img/brand/audi.png" alt="Images">
            </div>
            <div class="brand-item">
                <img src="assets/img/brand/volkswagen.png" alt="Images">
            </div>
        </div>
    </div>
</div>
<!-- Brand Area End -->

<!-- Start Services Area -->
<div class="service-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="span-bg">Services</span>
            <h2>Always Here with Expert Care You Can Trust</h2>
            <p>At PD Automotive, we specialize in providing reliable, high-quality vehicle maintenance and repair services, ensuring your vehicle stays safe, efficient, and ready for the road ahead.</p>
        </div>

        <div class="row pt-45">

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services/bodywork">
                        <img src="assets/img/services/bodywork.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <i class='flaticon-racing'></i>
                        <i class="flaticon-racing service-icon-bg"></i>
                        <h3><a routerLink="/services/bodywork">Bodywork and Collision Repairs</a></h3>
                        <p>Restore and enhance your vehicle’s appearance by repairing damage and improving aesthetics.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services/inspection">
                        <img src="assets/img/services/inspection.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <i class='flaticon-racing'></i>
                        <i class="flaticon-racing service-icon-bg"></i>
                        <h3><a routerLink="/services/inspection">Inspection and WoF Services</a></h3>
                        <p>Including WoF, ensure your vehicle's safety, compliance, performance and roadworthiness.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services/maintenance">
                        <img src="assets/img/services/maintenance.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <i class="flaticon-racing"></i>
                        <i class="flaticon-racing service-icon-bg"></i>
                        <h3><a routerLink="/services/maintenance">Maintenance Services</a></h3>
                        <p>Routine checks to prevent breakdowns and ensure your vehicle's safety and performance.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services/electrical">
                        <img src="assets/img/services/electrical.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <i class='flaticon-racing'></i>
                        <i class="flaticon-racing service-icon-bg"></i>
                        <h3><a routerLink="/services/electrical">Electrical and Hybrid Services</a></h3>
                        <p>Address and repair issues in electrical system, ensuring all components function properly.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services/air-conditioning">
                        <img src="assets/img/services/aircon.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <i class='flaticon-racing'></i>
                        <i class="flaticon-racing service-icon-bg"></i>
                        <h3><a routerLink="/services/air-conditioning">Air Conditioning (AC) Services</a></h3>
                        <p>Maintain and repair your vehicle’s air conditioning system for optimal cooling and comfort.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services/repair">
                        <img src="assets/img/services/repair.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <i class='flaticon-racing'></i>
                        <i class="flaticon-racing service-icon-bg"></i>
                        <h3><a routerLink="/services/repair">Repair Services</a></h3>
                        <p>Fix vehicle issues and restore functionality from breakdowns or damage.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services/diagnostic">
                        <img src="assets/img/services/diagnostic.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <i class='flaticon-racing'></i>
                        <i class="flaticon-racing service-icon-bg"></i>
                        <h3><a routerLink="/services/diagnostic">Diagnostic Services</a></h3>
                        <p>Identify and assess vehicle issues using advanced tools to ensure accurate repairs.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services/transmission">
                        <img src="assets/img/services/transmission.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <i class='flaticon-racing'></i>
                        <i class="flaticon-racing service-icon-bg"></i>
                        <h3><a routerLink="/services/transmission">Transmission Services</a></h3>
                        <p>Maintain, repair, or replace transmission to ensure smooth gear shifting and optimal performance.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services/customization">
                        <img src="assets/img/services/customization.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <i class='flaticon-racing'></i>
                        <i class="flaticon-racing service-icon-bg"></i>
                        <h3><a routerLink="/services/customization">Customization Services</a></h3>
                        <p>Enhance vehicle’s performance and appearance through tailored modifications and upgrades.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Services Area End -->

<!-- Start Choose Area -->
<div class="choose-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="span-bg">Why Choose Us?</span>
            <h2>We've Got the Perfect Plan to Deliver the Best for You</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="choose-item">
                    <div class="choose-item-icon one-bg">
                        <i class='bx bx-help-circle'></i>
                    </div>
                    <h3>Expertise You Can Trust</h3>
                    <p>Our team of highly skilled technicians brings years of experience and knowledge to every service, ensuring your vehicle gets the care it deserves.</p>
                    <br>
                    <!-- <a routerLink="/team" class="read-more">Read More <i class="bx bx-right-arrow-alt"></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="choose-item">
                    <div class="choose-item-icon two-bg">
                        <i class='bx bx-time'></i>
                    </div>
                    <h3>Comprehensive Automotive Solutions</h3>
                    <p>From routine maintenance to complex repairs, we offer a full range of services to keep your vehicle running smoothly and safely.</p>
                    <!-- <a routerLink="/services" class="read-more">Read More <i class="bx bx-right-arrow-alt"></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                <div class="choose-item">
                    <div class="choose-item-icon three-bg">
                        <i class='bx bx-bulb'></i>
                    </div>
                    <h3>Customer-Centric Approach</h3>
                    <p>We prioritize your convenience and satisfaction, offering transparent communication, timely service, and a commitment to exceeding expectations every time.</p>
                    <!-- <a routerLink="/service-details" class="read-more">Read More <i class="bx bx-right-arrow-alt"></i></a> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Choose Area End -->

<!-- Start Testimonials Area -->
<!-- <div class="testimonials-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="span-bg">Testimonials</span>
            <h2>Let's Know About Our Client Says</h2>
        </div>

        <div class="testimonials-slider pt-45 owl-carousel owl-theme">
            <div class="testimonials-item">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-6">
                        <div class="testimonials-content">
                            <h3>Devit M. kolin</h3>
                            <span>CEO & Founder</span>
                            <p>Awesome dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitationaco laboris nisi ut aliquip commodo consequat.</p>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-6">
                        <div class="testimonials-img">
                            <img src="assets/img/testimonials/testimonials-img.jpg" alt="Images">
                            <div class="quote-text">
                                <i class='flaticon-left-quote'></i>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonials-item">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-6">
                        <div class="testimonials-content">
                            <h3>Robart D.dondoe</h3>
                            <span>CEO Of PD Automotive</span>
                            <p>Awesome dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitationaco laboris nisi ut aliquip commodo consequat.</p>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-6">
                        <div class="testimonials-img">
                            <img src="assets/img/testimonials/testimonials-img2.jpg" alt="Images">
                            <div class="quote-text">
                                <i class='bx bxs-quote-left'></i>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonials-item">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-6">
                        <div class="testimonials-content">
                            <h3>Robart D.dondoe</h3>
                            <span>CEO Of PD Automotive</span>
                            <p>Awesome dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitationaco laboris nisi ut aliquip commodo consequat.</p>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-6">
                        <div class="testimonials-img">
                            <img src="assets/img/testimonials/testimonials-img2.jpg" alt="Images">
                            <div class="quote-text">
                                <i class='bx bxs-quote-left'></i>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div> -->
<!-- Testimonials Area End -->

<!-- Start Pricing Area -->
<!-- <div class="pricing-area pricing-bg pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="span-bg">Pricing Plan</span>
            <h2>We Are Always Ready to Give You the Best Price Benefits</h2>
            <p>We have a good expert in the global market and this will help us to make a good image on our service market and globally.</p>
        </div>
       
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card">
                    <div class="pricing-card-into color-bg1">
                        <i class="bx bx-paper-plane pricing-icon color-1"></i>
                        <h3 class="color-1">STANDARD</h3>

                        <div class="price-rate">
                            <h2 class="color-1">$ 69.99</h2>
                            <span class="color-1">Weekly Package</span>
                        </div>

                        <ul>
                            <li><i class='bx bx-check'></i> Weekly Servicing Policy.</li>
                            <li><i class='bx bx-check'></i> Replacement Guarantee</li>
                            <li><i class='bx bx-check'></i> Unlimited Servicing.</li>
                            <li><del>Unlimited Parts Replacement.</del></li>
                            <li><del>Unlimited Membership.</del></li>
                        </ul>

                        <a routerLink="/" class="purchase-btn">PURCHASE NOW</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card">
                    <div class="pricing-card-into color-bg2">
                        <i class="bx bx-paper-plane pricing-icon color-2"></i>
                        <h3 class="color-2">ECONOMY</h3>

                        <div class="price-rate">
                            <h2 class="color-2">$ 79.99</h2>
                            <span class="color-2">Monthly Package</span>
                        </div>

                        <ul>
                            <li><i class='bx bx-check'></i>Monthly Servicing Policy.</li>
                            <li><i class='bx bx-check'></i>Replacement Guarantee</li>
                            <li><i class='bx bx-check'></i>Unlimited Servicing.</li>
                            <li><i class='bx bx-check'></i>Unlimited Parts Replacement</li>
                            <li><del>Unlimited Membership.</del></li>
                        </ul>

                        <a routerLink="/" class="purchase-btn">PURCHASE NOW</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="pricing-card">
                    <div class="pricing-card-into color-bg3">
                        <i class="bx bx-paper-plane pricing-icon color-3"></i>
                        <h3 class="color-3">EXECUTIVE</h3>

                        <div class="price-rate">
                            <h2 class="color-3">$ 89.99</h2>
                            <span class="color-3">Yearly Package</span>
                        </div>

                        <ul>
                            <li><i class='bx bx-check'></i>Yearly Servicing Policy.</li>
                            <li><i class='bx bx-check'></i>Replacement Guarantee</li>
                            <li><i class='bx bx-check'></i>Unlimited Servicing.</li>
                            <li><i class='bx bx-check'></i>Unlimited Parts Replacement</li>
                            <li><i class='bx bx-check'></i>Unlimited Membership</li>
                        </ul>

                        <a routerLink="/" class="purchase-btn">PURCHASE NOW</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- Pricing Area End -->

<!-- Start Counter Area -->
<div class="counter-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-counter">
                    <i class="flaticon-rating icon-color-1"></i>
                    <h3>120+</h3>
                    <span>Dedicated Clients</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-counter">
                    <i class="flaticon-service icon-color-2"></i>
                    <h3>250+</h3>
                    <span>Car Repairs</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-counter">
                    <i class="flaticon-user icon-color-3"></i>
                    <h3>4</h3>
                    <span>Dedicated Experts</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-counter">
                    <i class="flaticon-calendar icon-color-4"></i>
                    <h3>15+</h3>
                    <span>Years Experience</span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Counter Area End -->

<!-- Start Project Area -->
<div class="project-area-two ptb-100">
    <div class="container-fluid p-0 m-0">
        <div class="section-title text-center">
            <span class="span-bg">Our Projects</span>
            <h2>Some Of Our Projects Work Where We Helped</h2>
            <p>Explore Our Projects to see how PD Automotive has tackled a range of automotive challenges with precision and dedication, delivering top-notch repair, maintenance, and customization solutions for our clients.</p>
        </div>

        <div class="project-slider pt-45 owl-carousel owl-theme">
            <div class="single-project">
                <img src="assets/img/project/project-a/pa_1050x950.jpg" alt="Images">
                <a routerLink="/projects/project-a">
                    Collision Repair - Mazda 3 2019 
                    <i class="bx bx-right-arrow-alt"></i>
                </a>
            </div>

            <div class="single-project">
                <img src="assets/img/project/project-b/pb_1050x950.jpg" alt="Images">
                <a routerLink="/projects/project-b">
                    Collision Repair - Toyota Corolla 2017
                    <i class="bx bx-right-arrow-alt"></i>
                </a>
            </div>

            <div class="single-project">
                <img src="assets/img/project/project-c/pc_1050x950.jpg" alt="Images">
                <a routerLink="/projects/project-c">
                    Collision Repair - Mitsubishi Outlander PHEV 2022 
                    <i class="bx bx-right-arrow-alt"></i>
                </a>
            </div>

            <div class="single-project">
                <img src="assets/img/project/project-d/pd_1050x950.jpg" alt="Images">
                <a routerLink="/projects/project-d">
                    Rust Repair - Landrover Discovery 4 
                    <i class="bx bx-right-arrow-alt"></i>
                </a>
            </div>
        </div>
    </div>
</div>
<!-- Project Area End -->

<!-- Start Team Area -->
<div class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="span-bg">Our Team</span>
            <h2>Meet the Experts Behind Your Trusted Automotive Care</h2>
            <p>Our skilled and dedicated team at PD Automotive brings years of experience and passion to every vehicle we service, ensuring you receive the best care and attention for your car.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/team/mechanical-ac.jpg" alt="Images">
                    </div>

                    <div class="content">
                        <h3>Mechanical & AC Technicians</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/team/electrical-diagnostic.jpg" alt="Images">
                    </div>

                    <div class="content">
                        <h3>Electrical &amp; Diagnostic Technicians</h3>
                    </div>
                </div> 
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/team/body-repair-painting.jpg" alt="Images">
                    </div>

                    <div class="content">
                        <h3>Panel Beating &amp; Painting Technicians</h3>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</div>
<!-- Team Area End -->

<!-- Start Appointment Area -->
<app-appointment [enableBreadCrumb]="false"></app-appointment>
<!-- Appointment Area End -->



<!-- Start Blog Area -->
<!-- Blog Area End -->