import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;
  message: string = '';
  API_ENDPOINT = 'https://qjxjn0lcqg.execute-api.ap-southeast-2.amazonaws.com/dev/contact';

  constructor(private fb: FormBuilder, private httpClient: HttpClient) { 
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.pattern('^\\+?[\\d\\s-]{10,}$')]],
      subject: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    if (this.contactForm.valid) {
      this.httpClient
        .post(this.API_ENDPOINT, this.contactForm.value)
        .subscribe({
          next: () => {
            this.message = 'Message sent successfully!';
            this.contactForm.reset();
          },
          error: (err) => {
            console.error(err);
            this.message = 'Failed to send message. Please try again.';
          },
        });
    }
  }

}
