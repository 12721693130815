<!-- Start Inner Banner -->
<div class="inner-banner inner-bg10">
    <div class="container">
        <div class="inner-title text-center">
            <h3> Projects Details </h3>
            <ul>
                <li>
                    <a routerLink="/projects">Projects</a>
                </li>
                <li>
                    <i class='bx bxs-chevron-right'></i>
                </li>
                <li> Collision Repair - Toyota Corolla 2017 </li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Projects Details Area -->
<div class="project-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="project-details-slider owl-carousel owl-theme">
                    <div class="project-details-item">
                        <img src="assets/img/project/project-b/pb_1000x600_1.jpg" alt="Images">
                    </div>

                    <div class="project-details-item">
                        <img src="assets/img/project/project-b/pb_1000x600_2.jpg" alt="Images">
                    </div>

                    <div class="project-details-item">
                        <img src="assets/img/project/project-b/pb_1000x600_3.jpg" alt="Images">
                    </div>

                    <div class="project-details-item">
                        <img src="assets/img/project/project-b/pb_1000x600_4.jpg" alt="Images">
                    </div>

                    <div class="project-details-item">
                        <img src="assets/img/project/project-b/pb_1000x600_5.jpg" alt="Images">
                    </div>

                    <div class="project-details-item">
                        <img src="assets/img/project/project-b/pb_1000x600_6.jpg" alt="Images">
                    </div>

                    <div class="project-details-item">
                        <img src="assets/img/project/project-b/pb_1000x600_7.jpg" alt="Images">
                    </div>

                    <div class="project-details-item">
                        <img src="assets/img/project/project-b/pb_1000x600_8.jpg" alt="Images">
                    </div>

                    <div class="project-details-item">
                        <img src="assets/img/project/project-b/pb_1000x600_9.jpg" alt="Images">
                    </div>
                </div>

                <!-- Project Gallery Area -->
                <div class="service-area pt-50 pb-70">

                    <div class="row pt-45">

                        <div class="col-lg-4 col-md-6">
                            <div class="service-card">
                                <img src="assets/img/project/project-b/pb_1050x916_1.jpg" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="service-card">
                                <img src="assets/img/project/project-b/pb_1050x916_2.jpg" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="service-card">
                                <img src="assets/img/project/project-b/pb_1050x916_3.jpg" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="service-card">
                                <img src="assets/img/project/project-b/pb_1050x916_4.jpg" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="service-card">
                                <img src="assets/img/project/project-b/pb_1050x916_5.jpg" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="service-card">
                                <img src="assets/img/project/project-b/pb_1050x916_6.jpg" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="service-card">
                                <img src="assets/img/project/project-b/pb_1050x916_7.jpg" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="service-card">
                                <img src="assets/img/project/project-b/pb_1050x916_8.jpg" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="service-card">
                                <img src="assets/img/project/project-b/pb_1050x916_9.jpg" alt="Images">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="project-side-bar">
                    <div class="project-categories">
                        <h3>Mazda 3 2019</h3>

                        <ul>
                            <li>
                                <a href="#">Brand</a>
                                <span>Toyota</span>
                            </li>
                            <li>
                                <a href="#">Model</a>
                                <span>Corolla</span>
                            </li>
                            <li>
                                <a href="#">Model Year</a>
                                <span>2017</span>
                            </li>
                            <li>
                                <a href="#">Category</a>
                                <span>Collision Repair</span>
                            </li>
                        </ul>
                    </div>

                    <!-- <div class="side-bar-from">
                        <h3>SCHEDULE AN APPOINTMENT</h3>

                        <div class="appointment-form">
                            <form>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Phone">
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <select class="form-control">
                                                <option>Select your services</option>
                                                <option>Bodywork and Collision Repairs</option>
                                                <option>Inspection and WoF Services</option>
                                                <option>Maintenance Services</option>
                                                <option>Electrical and Hybrid Services</option>
                                                <option>Air Conditioning(AC) Services</option>
                                                <option>Repair Services</option>
                                                <option>Diagnostic Services</option>
                                                <option>Customization Services</option>
                                            </select>	
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" placeholder="Your Message"></textarea>
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> -->

                    <!-- <div class="subscribe-area">
                        <span>SUBSCRIBE</span>
                        <h2>SUBSCRIBE FOR NEWSLETTER</h2>
                        <form class="subscribe-form">
                            <input type="email" class="form-control" placeholder="Email*" name="EMAIL">
                            <button class="default-btn" type="submit">SUBSCRIBE  </button>
                        </form>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Projects Details Area End -->