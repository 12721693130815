import { Component } from '@angular/core';

@Component({
  selector: 'app-air-conditioning',
  templateUrl: './air-conditioning.component.html',
  styleUrl: './air-conditioning.component.scss'
})
export class AirConditioningComponent {

}
