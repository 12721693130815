<!-- Start Inner Banner -->
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Inspection and WoF Services</h3>
            <ul>
                <li><a routerLink="/services">Services</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Inspection and WoF Services</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Services Details Area -->
<div class="service-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-article">
                    <div class="service-article-img">
                        <img src="assets/img/services/details/inspection-details.jpg" alt="Images">
                    </div>

                    <div class="service-article-content">
                        <h2>Inspection and WoF Services</h2>
                        <p>
                            At PD Automotive, our Inspection and Warrant of Fitness (WoF) services are designed to ensure that vehicles meet safety and regulatory standards for roadworthiness. 
                            Our trained technicians conduct thorough inspections to identify any issues that may affect the vehicle's safety, performance, or compliance with legal requirements. 
                            Below is a comprehensive list of automotive inspection and WoF tasks we offer:
                        </p>
                    </div>

                    <div class="service-article-list">
                        <h2>Inspection and WoF Service Tasks at PD Automotive</h2>
                        <p>our dedication to thorough inspection and WoF services ensures that every vehicle we evaluate meets the highest safety and performance standards. Our experienced technicians provide reliable assessments to help our customers stay safe on the road while adhering to legal requirements.</p>
                        <ul>
                            <li><i class='bx bx-check-circle'></i> Warrant of Fitness (WoF) Certification</li>
                            <li><i class='bx bx-check-circle'></i> Safety Inspection</li>
                            <li><i class='bx bx-check-circle'></i> Emissions Testing</li>
                            <li><i class='bx bx-check-circle'></i> Brake System Inspection</li>
                            <li><i class='bx bx-check-circle'></i> Suspension System Inspection</li>
                            <li><i class='bx bx-check-circle'></i> Tire Inspection</li>
                            <li><i class='bx bx-check-circle'></i> Lighting System Inspection</li>
                            <li><i class='bx bx-check-circle'></i> Steering System Inspection</li>
                            <li><i class='bx bx-check-circle'></i> Exhaust System Inspection</li>
                            <li><i class='bx bx-check-circle'></i> Windshield and Glass Inspection</li>
                            <li><i class='bx bx-check-circle'></i> Battery and Electrical System Inspection</li>
                            <li><i class='bx bx-check-circle'></i> Fluid Level and Condition Check</li>
                            <li><i class='bx bx-check-circle'></i> Body Condition Inspection</li>
                            <li><i class='bx bx-check-circle'></i> Interior Safety Feature Inspection</li>
                            <li><i class='bx bx-check-circle'></i> Pre-Purchase Inspection</li>
                            <li><i class='bx bx-check-circle'></i> Post-Repair Inspection</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="service-details-sidebar">
                    <div class="service-details-widget">
                        <h3 class="title">All Services</h3>

                        <div class="service-details-categories">
                            <ul>
                                <li><a routerLink="/services/bodywork">Bodywork and Collision Repairs</a></li>
                                <li><a routerLink="/services/inspection">Inspection and WoF Services</a></li>
                                <li><a routerLink="/services/maintenance">Maintenance Services</a></li>
                                <li><a routerLink="/services/electrical">Electrical Services</a></li>
                                <li><a routerLink="/services/air-conditioning">Air Conditioning (AC) Services</a></li>
                                <li><a routerLink="/services/repair">Repair Services</a></li>
                                <li><a routerLink="/services/diagnostic">Diagnostic Services</a></li>
                                <li><a routerLink="/services/transmission">Transmission Services</a></li>
                                <li><a routerLink="/services/customization">Customization Services</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="service-details-widget">
                        <h3 class="title">Contact Us</h3>

                        <div class="row pt-20">
                            <div class="col-lg-12 col-md-4">
                                <div class="service-widget-card">
                                    <i class='bx bx-home-smile'></i>
                                    <div class="content">
                                        <h3>Address</h3>
                                        <p>147 Benmore Avenue, Cloverlea, </p>
                                        <span>Palmerston North 4412</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-4">
                                <div class="service-widget-card">
                                    <i class='bx bx-phone-call'></i>
                                    <div class="content">
                                        <h3>Phone</h3>
                                        <p><a href="tel:+6462137137">06 213 7137</a></p>
                                        <p><a href="tel:+642102662646">0210 266 2646</a></p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-4">
                                <div class="service-widget-card">
                                    <i class='bx bxs-envelope'></i>
                                    <div class="content">
                                        <h3>Email</h3>
                                        <p><a href="mailto:info&#64;pdautomotive.co.nz">info&#64;pdautomotive.co.nz</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="subscribe-area">
                        <span>SUBSCRIBE</span>
                        <h2>SUBSCRIBE FOR NEWSLETTER</h2>
                        <form class="subscribe-form">
                            <input type="email" class="form-control" placeholder="Email*" name="EMAIL">
                            <button class="default-btn" type="submit">SUBSCRIBE</button>
                        </form>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Services Details Area End -->

