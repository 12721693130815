import { Component } from '@angular/core';

@Component({
  selector: 'app-electrical',
  templateUrl: './electrical.component.html',
  styleUrl: './electrical.component.scss'
})
export class ElectricalComponent {

}
