<!-- Start Top Header -->
<header class="top-header">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-9">
                <div class="header-left">
                    <div class="header-left-card">
                        <ul>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bx-home-smile'></i>
                                </div>
                                <a href="#" target="_blank">147 Benmore Avenue, Cloverlea, Palmerston North 4412</a>
                            </li>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bx-phone-call'></i>
                                </div>
                                <a href="tel:+6462137137">06 213 7137</a>
                            </li>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bxs-envelope'></i>
                                </div>
                                <a href="mailto:info&#64;pdautomotive.co.nz">info&#64;pdautomotive.co.nz</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-3">
                <div class="header-right">
                    <div class="top-social-link">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <!-- <li><a href="#" target="_blank"><i class='bx bxl-google-plus'></i></a></li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- Top Header End -->

<!-- Start Navbar Area -->
<div class="navbar-area">

    <!-- Menu For Responsive -->
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/logo.png" alt="Logo">
        </a>
    </div>

    <!-- Menu For Desktop -->
    <div class="main-nav nav-bar">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo.png" alt="Logo">
                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a></li>
                        <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a></li>
                        <li class="nav-item"><a routerLink="/projects" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Projects</a></li>
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>

                    <div class="others-options d-flex align-items-center">
                        <!-- <div class="option-item">
                            <i class='search-btn bx bx-search'></i>
                            <i class='close-btn bx bx-x'></i>
                            <div class="search-overlay search-popup">
                                <div class='search-box'>
                                    <form class="search-form">
                                        <input class="search-input" name="search" placeholder="Search" type="text">
                                        <button class="search-button" type="submit"><i class="bx bx-search"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div> -->

                        <!-- <div class="option-item">
                            <div class="add-cart-btn">
                                <a routerLink="/cart" class="cart-btn-icon">
                                    <i class='bx bx-cart-alt'></i>
                                    <span>0</span>
                                </a>
                            </div>	
                        </div> -->
                    </div>
                </div>
            </nav>
        </div>
    </div>

</div>
<!-- End Navbar Area -->