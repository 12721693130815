<!-- Start Inner Banner -->
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Electrical and Hybrid Services</h3>
            <ul>
                <li><a routerLink="/services">Services</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Electrical and Hybrid Services</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Services Details Area -->
<div class="service-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-article">
                    <div class="service-article-img">
                        <img src="assets/img/services/details/electrical-details.jpg" alt="Images">
                    </div>

                    <div class="service-article-content">
                        <h2>Electrical and Hybrid Services</h2>
                        <p>
                            At PD Automotive, our electrical services are focused on diagnosing and repairing a wide range of electrical issues within vehicles. 
                            Our skilled technicians utilize advanced diagnostic tools and techniques to ensure that every electrical component operates efficiently and reliably. 
                            Below is a comprehensive list of automotive electrical service tasks that we offer
                        </p>
                    </div>

                    <div class="service-article-list">
                        <h2>Electrical and Hybrid Service Tasks at PD Automotive</h2>
                        <p>
                            we are committed to providing high-quality electrical services for all types of vehicles, including hybrids. 
                            Our specialized knowledge and expertise in hybrid systems ensure that we can effectively address any electrical issues, 
                            helping our customers maintain their vehicles' performance and reliability.
                        </p>
                        <h4>Electrical Service Tasks</h4>
                        <ul>
                            <li><i class='bx bx-check-circle'></i> Battery Testing and Replacement</li>
                            <li><i class='bx bx-check-circle'></i> Alternator Testing and Repair</li>
                            <li><i class='bx bx-check-circle'></i> Starter Motor Repair and Replacement</li>
                            <li><i class='bx bx-check-circle'></i> Electrical System Diagnostics</li>
                            <li><i class='bx bx-check-circle'></i> Wiring Repair and Replacement</li>
                            <li><i class='bx bx-check-circle'></i> Fuses and Relay Replacement</li>
                            <li><i class='bx bx-check-circle'></i> Lighting System Repair</li>
                            <li><i class='bx bx-check-circle'></i> Power Window and Door Lock Repair</li>
                            <li><i class='bx bx-check-circle'></i> Heater and A/C Control Repair</li>
                            <li><i class='bx bx-check-circle'></i> Audio System Installation and Repair</li>
                            <li><i class='bx bx-check-circle'></i> Sensor Diagnostics and Replacement</li>
                            <li><i class='bx bx-check-circle'></i> Cruise Control System Repair</li>
                            <li><i class='bx bx-check-circle'></i> Electrical Component Installation</li>
                            <li><i class='bx bx-check-circle'></i> Airbag System Diagnostics and Repair</li>
                        </ul>
                        <br>
                        <h4>Hybrid Service Tasks</h4>
                        <ul>
                            <li><i class='bx bx-check-circle'></i> Hybrid Vehicle Battery Management System Diagnostics</li>
                            <li><i class='bx bx-check-circle'></i> Hybrid Drive System Diagnostics and Repair</li>
                            <li><i class='bx bx-check-circle'></i> Regenerative Braking System Diagnostics</li>
                            <li><i class='bx bx-check-circle'></i> Charging System Inspection and Repair</li>
                            <li><i class='bx bx-check-circle'></i> High-Voltage System Inspections</li>
                            <li><i class='bx bx-check-circle'></i> Hybrid Control Module Diagnostics</li>
                            <li><i class='bx bx-check-circle'></i> Hybrid Battery Cooling System Service and Repair</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="service-details-sidebar">
                    <div class="service-details-widget">
                        <h3 class="title">All Services</h3>

                        <div class="service-details-categories">
                            <ul>
                                <li><a routerLink="/services/bodywork">Bodywork and Collision Repairs</a></li>
                                <li><a routerLink="/services/inspection">Inspection and WoF Services</a></li>
                                <li><a routerLink="/services/maintenance">Maintenance Services</a></li>
                                <li><a routerLink="/services/electrical">Electrical Services</a></li>
                                <li><a routerLink="/services/air-conditioning">Air Conditioning (AC) Services</a></li>
                                <li><a routerLink="/services/repair">Repair Services</a></li>
                                <li><a routerLink="/services/diagnostic">Diagnostic Services</a></li>
                                <li><a routerLink="/services/transmission">Transmission Services</a></li>
                                <li><a routerLink="/services/customization">Customization Services</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="service-details-widget">
                        <h3 class="title">Contact Us</h3>

                        <div class="row pt-20">
                            <div class="col-lg-12 col-md-4">
                                <div class="service-widget-card">
                                    <i class='bx bx-home-smile'></i>
                                    <div class="content">
                                        <h3>Address</h3>
                                        <p>147 Benmore Avenue, Cloverlea, </p>
                                        <span>Palmerston North 4412</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-4">
                                <div class="service-widget-card">
                                    <i class='bx bx-phone-call'></i>
                                    <div class="content">
                                        <h3>Phone</h3>
                                        <p><a href="tel:+6462137137">06 213 7137</a></p>
                                        <p><a href="tel:+642102662646">0210 266 2646</a></p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-4">
                                <div class="service-widget-card">
                                    <i class='bx bxs-envelope'></i>
                                    <div class="content">
                                        <h3>Email</h3>
                                        <p><a href="mailto:info&#64;pdautomotive.co.nz">info&#64;pdautomotive.co.nz</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="subscribe-area">
                        <span>SUBSCRIBE</span>
                        <h2>SUBSCRIBE FOR NEWSLETTER</h2>
                        <form class="subscribe-form">
                            <input type="email" class="form-control" placeholder="Email*" name="EMAIL">
                            <button class="default-btn" type="submit">SUBSCRIBE</button>
                        </form>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Services Details Area End -->

