<!-- Start Inner Banner -->
<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title text-center">
            <h3>About Us</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start About Area -->
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img-2">
                    <img src="assets/img/about/about-img2.jpg" alt="Images">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <span class="span-bg">About Us</span>
                        <h2>Driven by Expertise, Fueled by Trust – Your Journey Starts with Us</h2>
                        <p>At PD Automotive, we’re passionate about keeping you safely on the road. Based in Palmerston North, New Zealand, 
                            our workshop brings together years of expertise and hands-on experience to provide top-notch automotive services. 
                            Whether it's regular maintenance, a complex repair, or simply ensuring your vehicle is road-ready, our team is here to help with precision and care.
                        </p>
                        <p>
                            We pride ourselves on delivering quality work and building lasting relationships with our customers. 
                            From routine oil changes to advanced diagnostics, we handle it all. Trust PD Automotive to keep your car running smoothly and reliably, every time.
                        </p>
                    </div>
                    <ul>
                        <li><i class='bx bx-check-circle'></i> Quality Workmanship</li>
                        <li><i class='bx bx-check-circle'></i> Expertise & Experience</li>
                        <li><i class='bx bx-check-circle'></i> Fair Pricing &amp; Transparency</li>
                        <li><i class='bx bx-check-circle'></i> Commitment to Safety</li>
                        <li><i class='bx bx-check-circle'></i> Customer Satisfaction</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About Area End -->

<!-- Start Choose Area -->
<div class="choose-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="span-bg">Why Choose Us?</span>
            <h2>We've Got the Perfect Plan to Deliver the Best for You</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="choose-item">
                    <div class="choose-item-icon one-bg">
                        <i class='bx bx-help-circle'></i>
                    </div>
                    <h3>Expertise You Can Trust</h3>
                    <p>Our team of highly skilled technicians brings years of experience and knowledge to every service, ensuring your vehicle gets the care it deserves.</p>
                    <br>
                    <!-- <a routerLink="/team" class="read-more">Read More <i class="bx bx-right-arrow-alt"></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="choose-item">
                    <div class="choose-item-icon two-bg">
                        <i class='bx bx-time'></i>
                    </div>
                    <h3>Comprehensive Automotive Solutions</h3>
                    <p>From routine maintenance to complex repairs, we offer a full range of services to keep your vehicle running smoothly and safely.</p>
                    <!-- <a routerLink="/services" class="read-more">Read More <i class="bx bx-right-arrow-alt"></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                <div class="choose-item">
                    <div class="choose-item-icon three-bg">
                        <i class='bx bx-bulb'></i>
                    </div>
                    <h3>Customer-Centric Approach</h3>
                    <p>We prioritize your convenience and satisfaction, offering transparent communication, timely service, and a commitment to exceeding expectations every time.</p>
                    <!-- <a routerLink="/service-details" class="read-more">Read More <i class="bx bx-right-arrow-alt"></i></a> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Choose Area End -->

<!-- Start Team Area -->
<div class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="span-bg">Our Team</span>
            <h2>We Have a Team of Experts Behind Your Trusted Automotive Care</h2>
            <p>Our skilled and dedicated team at PD Automotive brings years of experience and passion to every vehicle we service, ensuring you receive the best care and attention for your car.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/team/mechanical-ac.jpg" alt="Images">

                        <!-- <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            </ul>
                        </div> -->
                    </div>

                    <div class="content">
                        <h3>Mechanical & AC Technicians</h3>
                        <!-- <span>Founder - CEO</span> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/team/electrical-diagnostic.jpg" alt="Images">

                        <!-- <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            </ul>
                        </div> -->
                    </div>

                    <div class="content">
                        <h3>Electrical &amp; Diagnostic Technicians</h3>
                        <!-- <span>Co-Founder</span> -->
                    </div>
                </div> 
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/team/body-repair-painting.jpg" alt="Images">

                        <!-- <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            </ul>
                        </div> -->
                    </div>

                    <div class="content">
                        <h3>Body Repair &amp; Painting Technicians</h3>
                        <!-- <span>Manager</span> -->
                    </div>
                </div> 
            </div>
        </div>
    </div>
</div>
<!-- Team Area End -->

<!-- Start Appointment Area -->
<app-appointment [enableBreadCrumb]="false"></app-appointment>
<!-- Appointment Area End -->