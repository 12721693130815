<!-- Footer Area -->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="Images"></a>
                    </div>
                    <p>We keep you moving with precision, trust, <br> and unmatched care </p>
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="footer-widget">
                    <h3>Useful Links</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/about">About</a></li>
                        <li><a routerLink="/services-1">Services</a></li>
                        <li><a routerLink="/projects">Projects</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Other Resources</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/">Terms and Conditions</a></li>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Quality Policy</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-2">
                    <div class="footer-widget">
                        <h3>Address</h3>

                        <ul class="footer-list-two">
                            <li>
                                <i class='bx bx-time'></i>
                                Sun - Fri: 8.00am - 6.00pm
                            </li>
                            <li>
                                <i class='bx bx-home-smile'></i>
                                <a href="#" target="_blank">147 Benmore Avenue, Cloverlea, Palmerston North 4412</a>
                            </li>
                            <li>
                                <i class='bx bx-phone'></i>
                                <a href="tel:+6462137137">06 213 7137</a>
                            </li>
                            <li>
                                <i class='bx bx-mobile'></i>
                                <a href="tel:+642102662646">0210 266 2646</a>
                            </li>
                            <li>
                                <i class='bx bxs-envelope'></i>
                                <a href="mailto:info&#64;pdautomotive.co.nz">info&#64;pdautomotive.co.nz</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Footer Area End -->

<!-- Copyright Area -->
<div class="copy-right-area">
    <div class="container">
        <div class="copy-right-text text-center">
            <p>© 2024 <a href="https://marvelstacks.com/" target="_blank">MarvelStacks.</a> All Rights Reserved.</p>
        </div>
    </div>
</div>
<!-- Copyright Area End -->