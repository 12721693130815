<!-- Start Inner Banner -->
<div class="inner-banner inner-bg2">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Contact Us</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Contact Area -->
<div class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="contact-form">
                    <div class="section-title">
                        <span class="span-bg">CONTACT</span>
                        <h2>Keep in Touch</h2>
                        <p>Ready to give your vehicle the care it deserves? Reach out to our expert team and let’s get you back on the road with confidence.</p>
                    </div>
                    
                    <form id="contactForm" [formGroup]="contactForm" (ngSubmit)="onSubmit()" novalidate>
                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" formControlName="name" class="form-control" placeholder="Name">
                                    <div *ngIf="contactForm.get('name')?.invalid && contactForm.get('name')?.touched">
                                        <small class="text-danger" *ngIf="contactForm.get('name')?.errors?.['required']">Name is required.</small>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" formControlName="email" class="form-control" placeholder="Email">
                                    <div *ngIf="contactForm.get('email')?.invalid && contactForm.get('email')?.touched">
                                        <small class="text-danger" *ngIf="contactForm.get('email')?.errors?.['required']">Email is required.</small>
                                        <small class="text-danger" *ngIf="contactForm.get('email')?.errors?.['email']">Please enter a valid email address.</small>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" name="phone" id="phone" formControlName="phone" class="form-control" placeholder="Phone">
                                    <div *ngIf="contactForm.get('phone')?.invalid && contactForm.get('phone')?.touched">
                                        <small class="text-danger" *ngIf="contactForm.get('phone')?.errors?.['pattern']">Please enter a valid phone number.</small>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" name="subject" id="subject" formControlName="subject" class="form-control" placeholder="Subject">
                                    <div *ngIf="contactForm.get('subject')?.invalid && contactForm.get('subject')?.touched">
                                        <small class="text-danger" *ngIf="contactForm.get('subject')?.errors?.['required']">Subject is required.</small>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" formControlName="message" cols="30" rows="8" placeholder="Message"></textarea>
                                    <div *ngIf="contactForm.get('message')?.invalid && contactForm.get('message')?.touched">
                                        <small class="text-danger" *ngIf="contactForm.get('message')?.errors?.['required']">Message is required.</small>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn" [disabled]="!contactForm.valid">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="contact-sidebar">
                    <h2>Our Contact Details</h2>
                    <p>Got a question or need our help? We're just a call, or visit away. Reach out to us today</p>
                    <div class="row">
                        <div class="col-lg-12 col-md-4">
                            <div class="contact-card">
                                <i class='bx bx-home-smile'></i>
                                <div class="content">
                                    <h3>Address</h3>
                                    <p>147 Benmore Avenue, Cloverlea, </p>
                                    <span>Palmerston North 4412</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-4">
                            <div class="contact-card">
                                <i class='bx bx-phone-call'></i>
                                <div class="content">
                                    <h3>Phone</h3>
                                    <p><a href="tel:+6462137137">06 213 7137</a></p>
                                    <p><a href="tel:+642102662646">0210 266 2646</a></p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-4">
                            <div class="contact-card">
                                <i class='bx bxs-envelope'></i>
                                <div class="content">
                                    <h3>Email</h3>
                                    <p><a href="mailto:info&#64;pdautomotive.co.nz">info&#64;pdautomotive.co.nz</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->

<!-- Start Map Area -->
<!-- <div class="map-area">
    <div class="container-fluid m-0 p-0">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9922.444473680396!2d-0.12427675970922974!3d51.55702951532976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761b9fd875f4b1%3A0xc1f4679dc8b4bb01!2sHolloway%2C%20London%20N7%206PG%2C%20UK!5e0!3m2!1sen!2sbd!4v1601713197779!5m2!1sen!2sbd"></iframe>
    </div>
</div> -->
<!-- Map Area End -->