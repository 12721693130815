<!-- Start Inner Banner -->
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Customization Services</h3>
            <ul>
                <li><a routerLink="/services">Services</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Customization Services</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Services Details Area -->
<div class="service-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-article">
                    <div class="service-article-img">
                        <img src="assets/img/services/details/customization-details.jpg" alt="Images">
                    </div>

                    <div class="service-article-content">
                        <h2>Customization Services</h2>
                        <p>
                            At PD Automotive, our customization services are designed to enhance the aesthetics, functionality, and performance of vehicles according to our customers' preferences and needs. 
                            Our skilled technicians utilize quality materials and expert techniques to deliver personalized solutions. Below is a comprehensive list of automotive customization service tasks we offer:
                        </p>
                    </div>

                    <div class="service-article-list">
                        <h2>Customization Service Tasks at PD Automotive</h2>
                        <p>
                            Our customization services aim to help customers create a vehicle that reflects their personal style and enhances performance. 
                            We take pride in delivering high-quality workmanship and exceptional customer service, ensuring that every customization project meets our customers’ expectations.
                        </p>
                        <ul>
                            <li><i class='bx bx-check-circle'></i> Custom Paint Jobs</li>
                            <li><i class='bx bx-check-circle'></i> Key Programming</li>
                            <li><i class='bx bx-check-circle'></i> Sound System and Infotainment System Installation and Upgrades</li>
                            <li><i class='bx bx-check-circle'></i> Navigation, GPS and Communication Systems Installation</li>
                            <li><i class='bx bx-check-circle'></i> Security Systems, Monitoring Systems and Dash Camera Systems Installation</li>
                            <li><i class='bx bx-check-circle'></i> Body Kit Installation</li>
                            <li><i class='bx bx-check-circle'></i> Wheels and Tires Customization</li>
                            <li><i class='bx bx-check-circle'></i> Suspension Modifications</li>
                            <li><i class='bx bx-check-circle'></i> Exhaust System Upgrades</li>
                            <li><i class='bx bx-check-circle'></i> Lighting Customization</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="service-details-sidebar">
                    <div class="service-details-widget">
                        <h3 class="title">All Services</h3>

                        <div class="service-details-categories">
                            <ul>
                                <li><a routerLink="/services/bodywork">Bodywork and Collision Repairs</a></li>
                                <li><a routerLink="/services/inspection">Inspection and WoF Services</a></li>
                                <li><a routerLink="/services/maintenance">Maintenance Services</a></li>
                                <li><a routerLink="/services/electrical">Electrical Services</a></li>
                                <li><a routerLink="/services/air-conditioning">Air Conditioning (AC) Services</a></li>
                                <li><a routerLink="/services/repair">Repair Services</a></li>
                                <li><a routerLink="/services/diagnostic">Diagnostic Services</a></li>
                                <li><a routerLink="/services/transmission">Transmission Services</a></li>
                                <li><a routerLink="/services/customization">Customization Services</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="service-details-widget">
                        <h3 class="title">Contact Us</h3>

                        <div class="row pt-20">
                            <div class="col-lg-12 col-md-4">
                                <div class="service-widget-card">
                                    <i class='bx bx-home-smile'></i>
                                    <div class="content">
                                        <h3>Address</h3>
                                        <p>147 Benmore Avenue, Cloverlea, </p>
                                        <span>Palmerston North 4412</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-4">
                                <div class="service-widget-card">
                                    <i class='bx bx-phone-call'></i>
                                    <div class="content">
                                        <h3>Phone</h3>
                                        <p><a href="tel:+6462137137">06 213 7137</a></p>
                                        <p><a href="tel:+642102662646">0210 266 2646</a></p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-4">
                                <div class="service-widget-card">
                                    <i class='bx bxs-envelope'></i>
                                    <div class="content">
                                        <h3>Email</h3>
                                        <p><a href="mailto:info&#64;pdautomotive.co.nz">info&#64;pdautomotive.co.nz</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="subscribe-area">
                        <span>SUBSCRIBE</span>
                        <h2>SUBSCRIBE FOR NEWSLETTER</h2>
                        <form class="subscribe-form">
                            <input type="email" class="form-control" placeholder="Email*" name="EMAIL">
                            <button class="default-btn" type="submit">SUBSCRIBE</button>
                        </form>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Services Details Area End -->

