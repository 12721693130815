<!-- Start Inner Banner -->
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Services</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Services Area -->
<div class="service-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="span-bg">Services</span>
            <h2>Always Here with Expert Care You Can Trust</h2>
            <p>At PD Automotive, we specialize in providing reliable, high-quality vehicle maintenance and repair services, ensuring your vehicle stays safe, efficient, and ready for the road ahead.</p>
        </div>

        <div class="row pt-45">

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services/bodywork">
                        <img src="assets/img/services/bodywork.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <i class='flaticon-racing'></i>
                        <i class="flaticon-racing service-icon-bg"></i>
                        <h3><a routerLink="/services/bodywork">Bodywork and Collision Repairs</a></h3>
                        <p>Restore and enhance your vehicle’s appearance by repairing damage and improving aesthetics.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services/inspection">
                        <img src="assets/img/services/inspection.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <i class='flaticon-racing'></i>
                        <i class="flaticon-racing service-icon-bg"></i>
                        <h3><a routerLink="/services/inspection">Inspection and WoF Services</a></h3>
                        <p>Including WoF, ensure your vehicle's safety, compliance, performance and roadworthiness.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services/maintenance">
                        <img src="assets/img/services/maintenance.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <i class="flaticon-racing"></i>
                        <i class="flaticon-racing service-icon-bg"></i>
                        <h3><a routerLink="/services/maintenance">Maintenance Services</a></h3>
                        <p>Routine checks to prevent breakdowns and ensure your vehicle's safety and performance.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services/electrical">
                        <img src="assets/img/services/electrical.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <i class='flaticon-racing'></i>
                        <i class="flaticon-racing service-icon-bg"></i>
                        <h3><a routerLink="/services/electrical">Electrical and Hybrid Services</a></h3>
                        <p>Address and repair issues in electrical system, ensuring all components function properly.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services/air-conditioning">
                        <img src="assets/img/services/aircon.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <i class='flaticon-racing'></i>
                        <i class="flaticon-racing service-icon-bg"></i>
                        <h3><a routerLink="/services/air-conditioning">Air Conditioning (AC) Services</a></h3>
                        <p>Maintain and repair your vehicle’s air conditioning system for optimal cooling and comfort.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services/repair">
                        <img src="assets/img/services/repair.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <i class='flaticon-racing'></i>
                        <i class="flaticon-racing service-icon-bg"></i>
                        <h3><a routerLink="/services/repair">Repair Services</a></h3>
                        <p>Fix vehicle issues and restore functionality from breakdowns or damage.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services/diagnostic">
                        <img src="assets/img/services/diagnostic.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <i class='flaticon-racing'></i>
                        <i class="flaticon-racing service-icon-bg"></i>
                        <h3><a routerLink="/services/diagnostic">Diagnostic Services</a></h3>
                        <p>Identify and assess vehicle issues using advanced tools to ensure accurate repairs.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services/transmission">
                        <img src="assets/img/services/transmission.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <i class='flaticon-racing'></i>
                        <i class="flaticon-racing service-icon-bg"></i>
                        <h3><a routerLink="/services/transmission">Transmission Services</a></h3>
                        <p>Maintain, repair, or replace transmission to ensure smooth gear shifting and optimal performance.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services/customization">
                        <img src="assets/img/services/customization.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <i class='flaticon-racing'></i>
                        <i class="flaticon-racing service-icon-bg"></i>
                        <h3><a routerLink="/services/customization">Customization Services</a></h3>
                        <p>Enhance vehicle’s performance and appearance through tailored modifications and upgrades.</p>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/services-2" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/services-2" class="page-numbers">2</a>
                    <a routerLink="/services-2" class="page-numbers">3</a>
                    <a routerLink="/services-2" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div> -->
        </div>
    </div>
</div>
<!-- Services Area End -->