<!-- Start Inner Banner -->
<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Blog Grid</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Blog Grid</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Blog Area -->
<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Blog</span>
            <h2>Latest Blog Post</h2>
            <p>We have more than one year work blog and we have a fantastic blog on the global market. This is really helpful for the end user.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog/blog-img1.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <span>January 01, 2024</span>
                        <h3><a routerLink="/blog-details">Modern Technology Has Invested Globally</a></h3>
                        <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog/blog-img2.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <span>August 01, 2024</span>
                        <h3><a routerLink="/blog-details">International Car Trade Make a Deal for Us</a></h3>
                        <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog/blog-img3.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <span>September 09, 2024</span>
                        <h3><a routerLink="/blog-details">Marketing Policy Added on the Policy Service</a></h3>
                        <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog/blog-img4.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <span>September 20, 2024</span>
                        <h3><a routerLink="/blog-details">How Do You Check and Changed Your Car’s Engine Oil</a></h3>
                        <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog/blog-img6.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <span>June 10, 2024</span>
                        <h3><a routerLink="/blog-details">Top 10 Automotive Advancements In Hrer</a></h3>
                        <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog/blog-img5.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <span>April 20, 2024</span>
                        <h3><a routerLink="/blog-details">How Do You Check and Changed Your Car’s Tyre</a></h3>
                        <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/blog-1" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/blog-1" class="page-numbers">2</a>
                    <a routerLink="/blog-1" class="page-numbers">3</a>
                    <a routerLink="/blog-1" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Blog Area End -->