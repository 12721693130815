<!-- Start Inner Banner -->
<div class="inner-banner inner-bg10">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Projects</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Projects</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Project Area -->
<div class="project-area-two ptb-100">
    <div class="container-fluid p-0 m-0">
        <div class="section-title text-center">
            <span class="span-bg">Our Projects</span>
            <h2>Some Of Our Projects Work Where We Helped</h2>
            <p>Explore Our Projects to see how PD Automotive has tackled a range of automotive challenges with precision and dedication, delivering top-notch repair, maintenance, and customization solutions for our clients.</p>
        </div>

        <div class="project-slider pt-45 owl-carousel owl-theme">
            <div class="single-project">
                <img src="assets/img/project/project-a/pa_1050x950.jpg" alt="Images">
                <a routerLink="/projects/project-a">
                    Collision Repair - Mazda 3 2019 
                    <i class="bx bx-right-arrow-alt"></i>
                </a>
            </div>

            <div class="single-project">
                <img src="assets/img/project/project-b/pb_1050x950.jpg" alt="Images">
                <a routerLink="/projects/project-b">
                    Collision Repair - Toyota Corolla 2017
                    <i class="bx bx-right-arrow-alt"></i>
                </a>
            </div>

            <div class="single-project">
                <img src="assets/img/project/project-c/pc_1050x950.jpg" alt="Images">
                <a routerLink="/projects/project-c">
                    Collision Repair - Mitsubishi Outlander PHEV 2022 
                    <i class="bx bx-right-arrow-alt"></i>
                </a>
            </div>

            <div class="single-project">
                <img src="assets/img/project/project-d/pd_1050x950.jpg" alt="Images">
                <a routerLink="/projects/project-d">
                    Rust Repair - Landrover Discovery 4 
                    <i class="bx bx-right-arrow-alt"></i>
                </a>
            </div>
        </div>
    </div>
</div>
<!-- Project Area End -->

<!-- Start Choose Area -->
<div class="choose-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="span-bg">Why Choose Us?</span>
            <h2>We've Got the Perfect Plan to Deliver the Best for You</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="choose-item">
                    <div class="choose-item-icon one-bg">
                        <i class='bx bx-help-circle'></i>
                    </div>
                    <h3>Expertise You Can Trust</h3>
                    <p>Our team of highly skilled technicians brings years of experience and knowledge to every service, ensuring your vehicle gets the care it deserves.</p>
                    <br>
                    <!-- <a routerLink="/team" class="read-more">Read More <i class="bx bx-right-arrow-alt"></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="choose-item">
                    <div class="choose-item-icon two-bg">
                        <i class='bx bx-time'></i>
                    </div>
                    <h3>Comprehensive Automotive Solutions</h3>
                    <p>From routine maintenance to complex repairs, we offer a full range of services to keep your vehicle running smoothly and safely.</p>
                    <!-- <a routerLink="/services" class="read-more">Read More <i class="bx bx-right-arrow-alt"></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                <div class="choose-item">
                    <div class="choose-item-icon three-bg">
                        <i class='bx bx-bulb'></i>
                    </div>
                    <h3>Customer-Centric Approach</h3>
                    <p>We prioritize your convenience and satisfaction, offering transparent communication, timely service, and a commitment to exceeding expectations every time.</p>
                    <!-- <a routerLink="/service-details" class="read-more">Read More <i class="bx bx-right-arrow-alt"></i></a> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Choose Area End -->

<!-- Start Team Area -->
<div class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="span-bg">Our Team</span>
            <h2>We Have a Team of Experts Behind Your Trusted Automotive Care</h2>
            <p>Our skilled and dedicated team at PD Automotive brings years of experience and passion to every vehicle we service, ensuring you receive the best care and attention for your car.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/team/mechanical-ac.jpg" alt="Images">
                    </div>

                    <div class="content">
                        <h3>Mechanical & AC Technicians</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/team/electrical-diagnostic.jpg" alt="Images">
                    </div>

                    <div class="content">
                        <h3>Electrical &amp; Diagnostic Technicians</h3>
                    </div>
                </div> 
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/team/body-repair-painting.jpg" alt="Images">
                    </div>

                    <div class="content">
                        <h3>Body Repair &amp; Painting Technicians</h3>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</div>
<!-- Team Area End -->

<!-- Start Appointment Area -->
<app-appointment [enableBreadCrumb]="false"></app-appointment>
<!-- Appointment Area End -->