import { Component } from '@angular/core';

@Component({
  selector: 'app-transmission',
  templateUrl: './transmission.component.html',
  styleUrl: './transmission.component.scss'
})
export class TransmissionComponent {

}
