<!-- Start Slider Area -->
<div class="slider-area">
    <div class="home-slider owl-carousel owl-theme">
        <div class="slider-item bg-item1">
            <div class="container">
                <div class="slider-content">
                    <span>Welcome to Our Service</span>
                    <h1>Fix Car & Get Care for Lifetime</h1>
                    <p>Since the year 1972 we are always ready to give you one of the best service. This will make a good image on the best service for us & will help from it.</p>
                    <div class="slider-btn">
                        <a routerLink="/" class="get-btn">Get Started</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="slider-item bg-item2">
            <div class="container">
                <div class="slider-content">
                    <span>Welcome to Our Service</span>
                    <h1>We Make Car Repair Hassle Free</h1>
                    <p>Since the year 1972 we are always ready to give you one of the best service. This will make a good image on the best service for us & will help from it.</p>
                    <div class="slider-btn">
                        <a routerLink="/" class="get-btn">Get Started</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="slider-item bg-item3">
            <div class="container">
                <div class="slider-content">
                    <span>Welcome to Our Service</span>
                    <h1>Your Car is Always Specials With Us</h1>
                    <p>Since the year 1972 we are always ready to give you one of the best service. This will make a good image on the best service for us & will help from it.</p>
                    <div class="slider-btn">
                        <a routerLink="/" class="get-btn">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Slider Area End -->

<!-- Start Choose Area -->
<div class="choose-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Why Choose Us?</span>
            <h2>We Have the Plan for You to Give You Best </h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="choose-card">
                    <div class="choose-icon one-bg">
                        <i class='bx bx-help-circle'></i>
                    </div>

                    <div class="content">
                        <h3>Problem Solver</h3>
                        <p>We have the best service for you to make and this is one of the best service.</p>
                        <a routerLink="/service-details" class="read-more-btn"><i class="left-icon bx bx-right-arrow-alt"></i> Read More <i class="right-icon bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="choose-card">
                    <div class="choose-icon two-bg">
                        <i class='bx bx-time'></i>
                    </div>

                    <div class="content">
                        <h3>On-time Deliveries</h3>
                        <p>We have the best service for you to make and this is one of the best service.</p>
                        <a routerLink="/service-details" class="read-more-btn"><i class="left-icon bx bx-right-arrow-alt"></i> Read More <i class="right-icon bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                <div class="choose-card">
                    <div class="choose-icon three-bg">
                        <i class='bx bx-bulb'></i>
                    </div>

                    <div class="content">
                        <h3>Services by Experts</h3>
                        <p>We have the best service for you to make and this is one of the best service.</p>
                        <a routerLink="/service-details" class="read-more-btn"><i class="left-icon bx bx-right-arrow-alt"></i> Read More <i class="right-icon bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Choose Area End -->

<!-- Start About Area -->
<div class="about-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="assets/img/about/about-img1.jpg" alt="Images">

                    <div class="about-img-shape">
                        <div class="shape-1"><img src="assets/img/about/shape1.png" alt="Images"></div>
                        <div class="shape-2"><img src="assets/img/about/shape2.png" alt="Images"></div>
                        <div class="shape-3"><img src="assets/img/about/shape2.png" alt="Images"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <span>About Us</span>
                        <h2>We Have 20+ Years of Service Experience for You</h2>
                        <p>We have a lots of plan to make a good and best & one of the  most modern car for you. We have a good experience and a good image on the global sector.</p>
                    </div>

                    <ul>
                        <li><i class='bx bx-check-circle'></i> Customer Satisfaction</li>
                        <li><i class='bx bx-check-circle'></i> Latest & Modern Shop</li>
                        <li><i class='bx bx-check-circle'></i> Expertise Diagnostics</li>
                        <li><i class='bx bx-check-circle'></i> Fair Pricing</li>
                        <li><i class='bx bx-check-circle'></i> Expert Care</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About Area End -->

<!-- Start Service Area -->
<div class="service-area pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Services</span>
            <h2>We Always Ready to Serve You the Best Service</h2>
            <p>We have a good expert in the global market and this will help us to make a good image on our service market and globally.</p>
        </div>

        <div class="service-slider owl-carousel owl-theme pt-45">
            <div class="service-item">
                <a routerLink="/service-details" class="service-icon">
                    <i class='flaticon-wheel'></i>
                </a>
                <h3><a routerLink="/service-details">Wheel Adjustment</a></h3>
                <p>We are always help to make one of the best adjustment service for us and all the details.</p>
                <a routerLink="/service-details" class="read-more-btn"><i class="left-icon bx bx-right-arrow-alt"></i> Read More <i class="right-icon bx bx-right-arrow-alt"></i></a>
            </div>

            <div class="service-item">
                <a routerLink="/service-details" class="service-icon">
                    <i class='flaticon-settings'></i>
                </a>
                <h3><a routerLink="/service-details">Engine Servicing</a></h3>
                <p>We are always help to make one of the best adjustment service for us and all the details.</p>
                <a routerLink="/service-details" class="read-more-btn"><i class="left-icon bx bx-right-arrow-alt"></i> Read More <i class="right-icon bx bx-right-arrow-alt"></i></a>
            </div>

            <div class="service-item">
                <a routerLink="/service-details" class="service-icon">
                    <i class='flaticon-fan'></i>
                </a>
                <h3><a routerLink="/service-details">Cooling Servicing</a></h3>
                <p>We are always help to make one of the best adjustment service for us and all the details.</p>
                <a routerLink="/service-details" class="read-more-btn"><i class="left-icon bx bx-right-arrow-alt"></i> Read More <i class="right-icon bx bx-right-arrow-alt"></i></a>
            </div>

            <div class="service-item">
                <a routerLink="/service-details" class="service-icon">
                    <i class='flaticon-service'></i>
                </a>
                <h3><a routerLink="/service-details">Engine Repair</a></h3>
                <p>We are always help to make one of the best adjustment service for us and all the details.</p>
                <a routerLink="/service-details" class="read-more-btn"><i class="left-icon bx bx-right-arrow-alt"></i> Read More <i class="right-icon bx bx-right-arrow-alt"></i></a>
            </div>
        </div>
    </div>
</div>
<!-- Service Area End -->

<!-- Start Pricing Area -->
<div class="pricing-area pricing-bg pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Pricing Plan</span>
            <h2>We Are Always Ready to Give You the Best Price Benefits</h2>
            <p>We have a good expert in the global market and this will help us to make a good image on our service market and globally.</p>
        </div>
       
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card">
                    <div class="pricing-card-into color-bg1">
                        <i class="bx bx-paper-plane pricing-icon color-1"></i>
                        <h3 class="color-1">STANDARD</h3>

                        <div class="price-rate">
                            <h2 class="color-1">$ 69.99</h2>
                            <span class="color-1">Weekly Package</span>
                        </div>
                        
                        <ul>
                            <li><i class='bx bx-check'></i> Weekly Servicing Policy.</li>
                            <li><i class='bx bx-check'></i> Replacement Guarantee</li>
                            <li><i class='bx bx-check'></i> Unlimited Servicing.</li>
                            <li><del>Unlimited Parts Replacement.</del></li>
                            <li><del>Unlimited Membership.</del></li>
                        </ul>

                        <a routerLink="/" class="purchase-btn">PURCHASE NOW</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card">
                    <div class="pricing-card-into color-bg2">
                        <i class="bx bx-paper-plane pricing-icon color-2"></i>
                        <h3 class="color-2">ECONOMY</h3>

                        <div class="price-rate">
                            <h2 class="color-2">$ 79.99</h2>
                            <span class="color-2">Monthly Package</span>
                        </div>

                        <ul>
                            <li><i class='bx bx-check'></i>Monthly Servicing Policy.</li>
                            <li><i class='bx bx-check'></i>Replacement Guarantee</li>
                            <li><i class='bx bx-check'></i>Unlimited Servicing.</li>
                            <li><i class='bx bx-check'></i>Unlimited Parts Replacement</li>
                            <li><del>Unlimited Membership.</del></li>
                        </ul>

                        <a routerLink="/" class="purchase-btn">PURCHASE NOW</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="pricing-card">
                    <div class="pricing-card-into color-bg3">
                        <i class="bx bx-paper-plane pricing-icon color-3"></i>
                        <h3 class="color-3">EXECUTIVE</h3>

                        <div class="price-rate">
                            <h2 class="color-3">$ 89.99</h2>
                            <span class="color-3">Yearly Package</span>
                        </div>

                        <ul>
                            <li><i class='bx bx-check'></i>Yearly Servicing Policy.</li>
                            <li><i class='bx bx-check'></i>Replacement Guarantee</li>
                            <li><i class='bx bx-check'></i>Unlimited Servicing.</li>
                            <li><i class='bx bx-check'></i>Unlimited Parts Replacement</li>
                            <li><i class='bx bx-check'></i>Unlimited Membership</li>
                        </ul>

                        <a routerLink="/" class="purchase-btn">PURCHASE NOW</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Pricing Area End -->

<!-- Start Achievements Area -->
<div class="achievements-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Achievements</span>
            <h2>We Are Always Famous for Our All Achievements</h2>
            <p>This is one of the best way to make a good achievements into the global world and this is really helpful for you.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-3 col-sm-6">
                <div class="achievements-card achievements-bg-1">
                    <i class="flaticon-rating"></i>
                    <h3>120K+</h3>
                    <span>Dedicated Clients</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="achievements-card achievements-bg-2">
                    <i class="flaticon-service"></i>
                    <h3>200K+</h3>
                    <span>Car Repair</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="achievements-card achievements-bg-3">
                    <i class="flaticon-user"></i>
                    <h3>170+</h3>
                    <span>Dedicated Experts</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="achievements-card achievements-bg-4">
                    <i class="flaticon-calendar"></i>
                    <h3>70+</h3>
                    <span>Years Experience</span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Achievements Area End -->

<!-- Start Project Area -->
<div class="project-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="project-title">
                    <div class="section-title">
                        <span>Our Projects</span>
                        <h2>Some Of Our Projects Work Where We Helped</h2>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="project-text">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et amet bibendum ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae pellentesque.</p>
                </div>
            </div>
        </div>

        <div class="tab project-tab">
            <ul class="tabs">
                <li><a href="#">Wheels</a></li>
                <li><a href="#">Steering</a></li>
                <li><a href="#">Brakes</a></li>
                <li><a href="#">Suspension</a></li>
                <li><a href="#">Tyre</a></li>
            </ul>

            <div class="tab_content current active pt-45">
                <div class="tabs_item current active">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img2.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Wheels</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img1.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Steering</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img3.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Tyre</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img4.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Steering</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img5.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Brakes</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img6.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Tyre</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img2.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Brakes</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img3.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Suspension</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img6.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Tyre</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img3.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Suspension</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img2.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Brakes</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img4.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Steering</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img4.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Steering</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img5.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Brakes</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img6.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Tyre</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Project Area End -->

<!-- Start Testimonials Area -->
<div class="testimonials-area testimonials-bg  pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Testimonials</span>
            <h2>Let's Know About Our Client Says</h2>
        </div>

        <div class="testimonials-slider pt-45 owl-carousel owl-theme">
            <div class="testimonials-item">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-6">
                        <div class="testimonials-content">
                            <h3>Devit M. kolin</h3>
                            <span>CEO & Founder</span>
                            <p>Awesome dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitationaco laboris nisi ut aliquip commodo consequat.</p>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-6">
                        <div class="testimonials-img">
                            <img src="assets/img/testimonials/testimonials-img.jpg" alt="Images">

                            <div class="quote-text">
                                <i class='flaticon-left-quote'></i>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonials-item">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-6">
                        <div class="testimonials-content">
                            <h3>Robart D.dondoe</h3>
                            <span>CEO Of Car Company</span>
                            <p>Awesome dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitationaco laboris nisi ut aliquip commodo consequat.</p>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-6">
                        <div class="testimonials-img">
                            <img src="assets/img/testimonials/testimonials-img2.jpg" alt="Images">

                            <div class="quote-text">
                                <i class='bx bxs-quote-left'></i>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Testimonials Area End -->

<!-- Start Team Area -->
<div class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Our Team</span>
            <h2>We Have an Expert & Dedicated Team Member</h2>
        </div>

        <div class="team-slider pt-45 owl-carousel owl-theme">
            <div class="team-item">
                <div class="team-img">
                    <img src="assets/img/team/team-img6.jpg" alt="Images">

                    <div class="social-icon">
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                        </ul>
                    </div>
                </div>

                <div class="content">
                    <h3>John Doe</h3>
                    <span>Founder - CEO</span>
                </div>
            </div>
            
            <div class="team-item">
                <div class="team-img">
                    <img src="assets/img/team/team-img3.jpg" alt="Images">

                    <div class="social-icon">
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                        </ul>
                    </div>
                </div>

                <div class="content">
                    <h3>Jenifar Ambrina</h3>
                    <span>Co-Founder</span>
                </div>
            </div> 

            <div class="team-item">
                <div class="team-img">
                    <img src="assets/img/team/team-img5.jpg" alt="Images">

                    <div class="social-icon">
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                        </ul>
                    </div>
                </div>

                <div class="content">
                    <h3>Johan Smith</h3>
                    <span>Manager</span>
                </div>
            </div> 

            <div class="team-item">
                <div class="team-img">
                    <img src="assets/img/team/team-img2.jpg" alt="Images">

                    <div class="social-icon">
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                        </ul>
                    </div>
                </div>

                <div class="content">
                    <h3>Sendy Alina</h3>
                    <span>Team Leader</span>
                </div>
            </div> 
        </div>
    </div>
</div>
<!-- Team Area End -->

<!-- Start Blog Area -->
<div class="blog-area pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Blogs</span>
            <h2>Latest Blog Post</h2>
            <p>We have more than one year work blog and we have a fantastic blog on the global market. This is really helpful for the end user.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog/blog-img1.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <span>January 01, 2024</span>
                        <h3><a routerLink="/blog-details">Modern Technology Has Invested Globally</a></h3>
                        <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog/blog-img2.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <span>August 01, 2024</span>
                        <h3><a routerLink="/blog-details">International Car Trade Make a Deal for Us</a></h3>
                        <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="blog-card">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog/blog-img3.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <span>September 09, 2024</span>
                        <h3><a routerLink="/blog-details">Marketing Policy Added on the Policy Service</a></h3>
                        <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Blog Area End -->