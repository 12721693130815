import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {

  @Input() enableBreadCrumb: boolean = true;

  bookingForm: FormGroup;
  message: string = '';
  serviceOptions: string[] = [
    'Bodywork and Collision Repairs', 
    'Inspection and WoF Services', 
    'Maintenance Services', 
    'Electrical and Hybrid Services',  
    'Air Conditioning(AC) Services', 
    'Repair Services',  
    'Diagnostic Services', 
    'Customization Services'
  ];
  private API_ENDPOINT = 'https://qjxjn0lcqg.execute-api.ap-southeast-2.amazonaws.com/dev/booking';

  constructor(private fb: FormBuilder, private httpClient: HttpClient) { 
    this.bookingForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern('^\\+?[\\d\\s-]{10,}$')]],
      plate: ['', [Validators.required, Validators.pattern('^[A-Z]{2}[0-9]{1,4}$|^[A-Z]{3}[0-9]{3}$|^[A-Z0-9\- ]{1,8}$')]],
      service: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    // Set default value if needed
    this.bookingForm.patchValue({
       service: this.serviceOptions[0]
    });
  }

  onSubmit(): void {
    console.log('Form Values:', this.bookingForm);
    if (this.bookingForm.valid) {
      console.log('Form Values:', this.bookingForm.value);
      this.httpClient.post(this.API_ENDPOINT, this.bookingForm.value)
        .subscribe({
          next: () => {
            this.message = 'Message sent successfully!';
            this.bookingForm.reset();
          },
          error: (err) => {
            console.error(err);
            this.message = 'Failed to send message. Please try again.';
          }
        });
    } else {
      this.markFormGroupTouched(this.bookingForm);
    }
  }
  // Helper method to mark all controls as touched
  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
    });
  }

  // Getter for easy access in template
  get f() {
    return this.bookingForm.controls;
  }

}
