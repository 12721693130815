<!-- Start Inner Banner -->
<div class="inner-banner inner-bg11">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Team</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li> Team </li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Team Area -->
<div class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="span-bg">Our Team</span>
            <h2>We Have an Expert & Dedicated Team Member</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/team/team-img1.jpg" alt="Images">

                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>John Doe</h3>
                        <span>Founder - CEO</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/team/team-img3.jpg" alt="Images">

                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>Jenifar Ambrina</h3>
                        <span>Co-Founder</span>
                    </div>
                </div> 
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/team/team-img4.jpg" alt="Images">

                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>Johan Smith</h3>
                        <span>Manager</span>
                    </div>
                </div> 
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/team/team-img2.jpg" alt="Images">

                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>Lufz Bolin</h3>
                        <span>Project Manager</span>
                    </div>
                </div> 
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/team/team-img5.jpg" alt="Images">

                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>Harry Nelis</h3>
                        <span>Chief Executive</span>
                    </div>
                </div> 
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/team/team-img6.jpg" alt="Images">

                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>Johan Henry</h3>
                        <span>Managing Director</span>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</div>
<!-- Team Area End -->