<!-- Start Inner Banner -->
<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Service Details</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Service Details</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Services Details Area -->
<div class="service-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-article">
                    <div class="service-article-img">
                        <img src="assets/img/services/services-details.jpg" alt="Images">
                    </div>

                    <div class="service-article-content">
                        <h2>Engine Services</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur suscipit massa ante, in ultrices mi ultrices vel. Maecenas egestas ex quis tempor lacinia. Suspendisse ipsum tellus, mattis vel ligula vel, tincidunt vulputate lacus. Suspendisse consequat porta urna, non faucibus turpis fringilla non. Cras auctor nibh a ipsum ornare tincidunt. Fusce semper lacus sed molestie molestie.In pulvinar tellus a purus gravida, non pharetra felis dictum. Sed sed mollis ligula. Fusce semper id lorem et ornare. Morbi semper dui eu est tincidunt, aliquet dictum sem commodo.</p>
                        <p>Nam aliquet molestie libero ut auctor. Integer interdum lacus eu nibh tempor pharetra. Suspendisse ornare massa et nulla tincidunt consequat. Nunc nec est vitae leo elementum commodo. Donec ac arcu dignissim, finibus turpis condimentum, accumsan leo. Suspendisse sit amet placerat dui, id bibendum elit. Proin suscipit est vel magna luctus dapibus. Morbi nec scelerisque ligula. Vivamus lectus nisl, interdum tempor faucibus at, tincidunt et metus. Pellentesque in rhoncus est. Maecenas suscipit sem mattis risus maximus convallis. Quisque feugiat, tortor vel dapibus porttitor.</p>
                    </div>

                    <div class="service-article-content">
                        <h2>The Common Problem</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur suscipit massa ante, in ultrices mi ultrices vel. Maecenas egestas ex quis tempor lacinia. Suspendisse ipsum tellus, mattis vel ligula vel, tincidunt vulputate lacus. Suspendisse consequat porta urna, non faucibus turpis fringilla non. Cras auctor nibh a ipsum ornare tincidunt. Fusce semper lacus sed molestie molestie.In pulvinar tellus a purus gravida, non pharetra felis dictum. Sed sed mollis ligula. Fusce semper id lorem et ornare. Morbi semper dui eu est tincidunt, aliquet dictum sem commodo.</p>
                    </div>

                    <div class="service-article-list">
                        <h2>Regular Engine Maintenance</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur suscipit massa ante, in ultrices mi ultrices vel. Maecenas egestas ex quis tempor lacinia. Suspendisse ipsum tellus, mattis vel ligula vel, tincidunt vulputate lacus. Suspendisse consequat porta urna, non faucibus turpis fringilla non. Cras auctor nibh a ipsum ornare tincidunt.</p>
                        <ul>
                            <li><i class='bx bx-check-circle'></i> Air and Fuel Filter Replacement Where Necessary</li>
                            <li><i class='bx bx-check-circle'></i> A Thorough Inspection of All Engine Component</li>
                            <li><i class='bx bx-check-circle'></i> Spark Plug Wire Inspection</li>
                            <li><i class='bx bx-check-circle'></i> Spark Plug Replacement</li>
                        </ul>
                    </div>

                    <div class="service-article-choose">
                        <h2>Why Choose Us</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur suscipit massa ante, in ultrices mi ultrices vel. Maecenas egestas ex quis tempor lacinia. Suspendisse ipsum tellus, mattis vel ligula vel, tincidunt vulputate lacus. Suspendisse consequat porta urna, non faucibus turpis fringilla non. Cras auctor nibh a ipsum ornare tincidunt. Fusce semper lacus sed molestie molestie.In pulvinar tellus a purus gravida, non pharetra felis dictum. Sed sed mollis ligula. Fusce semper id lorem et ornare. Morbi semper dui eu est tincidunt, aliquet dictum sem commodo. </p>
                        <ul>
                            <li><i class='bx bx-check-circle'></i> Customer Satisfaction</li>
                            <li><i class='bx bx-check-circle'></i> Latest & Modern shop</li>
                            <li><i class='bx bx-check-circle'></i> Expertise Diagnostics</li>
                            <li><i class='bx bx-check-circle'></i> Fair Pricing</li>
                            <li><i class='bx bx-check-circle'></i> Expert care</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="service-details-sidebar">
                    <div class="service-details-widget">
                        <h3 class="title">All Services</h3>

                        <div class="service-details-categories">
                            <ul>
                                <li><a routerLink="/serice-details">Maintenance Services</a></li>
                                <li><a routerLink="/serice-details">Repair Services</a></li>
                                <li><a routerLink="/serice-details">Diagnostic Services</a></li>
                                <li><a routerLink="/serice-details">Transmission Services</a></li>
                                <li><a routerLink="/serice-details">Bodywork and Cosmetic Services</a></li>
                                <li><a routerLink="/serice-details">Electrical Services</a></li>
                                <li><a routerLink="/serice-details">Air Conditioning (AC) Services</a></li>
                                <li><a routerLink="/serice-details">Inspection Services</a></li>
                                <li><a routerLink="/serice-details">Customization Services</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="service-details-widget">
                        <h3 class="title">Contact Us</h3>

                        <div class="row pt-20">
                            <div class="col-lg-12 col-md-4">
                                <div class="service-widget-card">
                                    <i class='bx bx-home-smile'></i>
                                    <div class="content">
                                        <h3>Address</h3>
                                        <p>147 Benmore Avenue, Cloverlea, </p>
                                        <span>Palmerston North 4412</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-4">
                                <div class="service-widget-card">
                                    <i class='bx bx-phone-call'></i>
                                    <div class="content">
                                        <h3>Phone</h3>
                                        <p><a href="tel:+6462137137">06 213 7137</a></p>
                                        <p><a href="tel:+642102662646">0210 266 2646</a></p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-4">
                                <div class="service-widget-card">
                                    <i class='bx bxs-envelope'></i>
                                    <div class="content">
                                        <h3>Email</h3>
                                        <p><a href="mailto:info&#64;pdautomotive.co.nz">info&#64;pdautomotive.co.nz</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="subscribe-area">
                        <span>SUBSCRIBE</span>
                        <h2>SUBSCRIBE FOR NEWSLETTER</h2>
                        <form class="subscribe-form">
                            <input type="email" class="form-control" placeholder="Email*" name="EMAIL">
                            <button class="default-btn" type="submit">SUBSCRIBE</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Services Details Area End -->