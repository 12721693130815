<!-- Start Inner Banner -->
<div class="inner-banner inner-bg1" *ngIf="enableBreadCrumb">
    <div class="container">
        <div class="inner-title text-center">
            <h3> Appointment </h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li> Appointment </li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Appointment Area -->
<div class="appointment-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="appointment-form">
                    <div class="section-title">
                        <h2>Book an Appointment</h2>
                        <p>Ready to give your vehicle the care it deserves? Book an appointment with PD Automotive for reliable, expert service that fits your schedule.</p>
                    </div>
                    <form id="bookingForm" [formGroup]="bookingForm" (ngSubmit)="onSubmit()" novalidate>
                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" formControlName="name" class="form-control" placeholder="Name">
                                    <div *ngIf="bookingForm.get('name')?.invalid && bookingForm.get('name')?.touched">
                                        <small class="text-danger" *ngIf="bookingForm.get('name')?.errors?.['required']">Name is required.</small>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" formControlName="email" class="form-control" placeholder="Email">
                                    <div *ngIf="bookingForm.get('email')?.invalid && bookingForm.get('email')?.touched">
                                        <small class="text-danger" *ngIf="bookingForm.get('email')?.errors?.['required']">Email is required.</small>
                                        <small class="text-danger" *ngIf="bookingForm.get('email')?.errors?.['email']">Please enter a valid email address.</small>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" name="phone" id="phone" formControlName="phone" class="form-control" placeholder="Phone">
                                    <div *ngIf="bookingForm.get('phone')?.invalid && bookingForm.get('phone')?.touched">
                                        <small class="text-danger" *ngIf="bookingForm.get('phone')?.errors?.['required']">Phone is required.</small>
                                        <small class="text-danger" *ngIf="bookingForm.get('phone')?.errors?.['pattern']">Please enter a valid phone number.</small>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" name="plate" id="plate" formControlName="plate" class="form-control" placeholder="Plate">
                                    <div *ngIf="bookingForm.get('plate')?.invalid && bookingForm.get('plate')?.touched">
                                        <small class="text-danger" *ngIf="bookingForm.get('plate')?.errors?.['required']">Registration plate is required.</small>
                                        <small class="text-danger" *ngIf="bookingForm.get('plate')?.errors?.['pattern']">Please enter a valid registration plate.</small>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select id="service" formControlName="service" class="form-control">
                                        <option value="" disabled>Select your services</option>
                                        <option *ngFor="let service of serviceOptions" [value]="service">
                                            {{service}}
                                        </option>
                                    </select>
                                    <div *ngIf="bookingForm.get('service')?.invalid && bookingForm.get('service')?.touched">
                                        <small class="text-danger" *ngIf="bookingForm.get('service')?.errors?.['required']">
                                            Please select a service type.
                                        </small>
                                    </div>  
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" formControlName="message" cols="30" rows="8" placeholder="Message"></textarea>
                                    <div *ngIf="bookingForm.get('message')?.invalid && bookingForm.get('message')?.touched">
                                        <small class="text-danger" *ngIf="bookingForm.get('message')?.errors?.['required']">Message is required.</small>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn" [disabled]="!bookingForm.valid">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="appointment-img">
                    <img src="assets/img/appointment-img.jpg" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Appointment Area End -->