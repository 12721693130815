import { Component } from '@angular/core';

@Component({
  selector: 'app-bodywork',
  templateUrl: './bodywork.component.html',
  styleUrl: './bodywork.component.scss'
})
export class BodyworkComponent {

}
