<!-- Start Inner Banner -->
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Air Conditioning (AC) Services</h3>
            <ul>
                <li><a routerLink="/services">Services</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Air Conditioning (AC) Services</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Services Details Area -->
<div class="service-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-article">
                    <div class="service-article-img">
                        <img src="assets/img/services/details/aircon-details.jpg" alt="Images">
                    </div>

                    <div class="service-article-content">
                        <h2>Air Conditioning (AC) Services</h2>
                        <p>
                            At PD Automotive, our Air Conditioning (AC) services are designed to ensure that your vehicle's climate control system operates effectively and efficiently. 
                            Our skilled technicians are equipped with the tools and expertise to diagnose, repair, and maintain automotive air conditioning systems. 
                            Below is a comprehensive list of automotive AC service tasks we offer
                        </p>
                    </div>

                    <div class="service-article-list">
                        <h2>Air Conditioning (AC) Service Tasks at PD Automotive</h2>
                        <p>Our commitment to comprehensive AC services ensures that your vehicle’s climate control system is functioning at its best. Our focus on quality and customer satisfaction helps ensure that you can enjoy a comfortable driving experience in any weather.</p>
                        <ul>
                            <li><i class='bx bx-check-circle'></i> AC System Inspection</li>
                            <li><i class='bx bx-check-circle'></i> Refrigerant Recharge</li>
                            <li><i class='bx bx-check-circle'></i> AC Leak Detection</li>
                            <li><i class='bx bx-check-circle'></i> Compressor Replacement</li>
                            <li><i class='bx bx-check-circle'></i> Condenser Repair or Replacement</li>
                            <li><i class='bx bx-check-circle'></i> Evaporator Repair or Replacement</li>
                            <li><i class='bx bx-check-circle'></i> AC Control System Diagnostics</li>
                            <li><i class='bx bx-check-circle'></i> Hose and Fitting Inspection and Replacement</li>
                            <li><i class='bx bx-check-circle'></i> AC System Flush</li>
                            <li><i class='bx bx-check-circle'></i> Climate Control System Repair</li>
                            <li><i class='bx bx-check-circle'></i> Deodorizing the AC System</li>
                            <li><i class='bx bx-check-circle'></i> Vent and Duct Inspection</li>
                            <li><i class='bx bx-check-circle'></i> Heater Core Inspection and Repair</li>
                            <li><i class='bx bx-check-circle'></i> AC Performance Testing</li>
                            <li><i class='bx bx-check-circle'></i> Seasonal AC Maintenance</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="service-details-sidebar">
                    <div class="service-details-widget">
                        <h3 class="title">All Services</h3>

                        <div class="service-details-categories">
                            <ul>
                                <li><a routerLink="/services/bodywork">Bodywork and Collision Repairs</a></li>
                                <li><a routerLink="/services/inspection">Inspection and WoF Services</a></li>
                                <li><a routerLink="/services/maintenance">Maintenance Services</a></li>
                                <li><a routerLink="/services/electrical">Electrical Services</a></li>
                                <li><a routerLink="/services/air-conditioning">Air Conditioning (AC) Services</a></li>
                                <li><a routerLink="/services/repair">Repair Services</a></li>
                                <li><a routerLink="/services/diagnostic">Diagnostic Services</a></li>
                                <li><a routerLink="/services/transmission">Transmission Services</a></li>
                                <li><a routerLink="/services/customization">Customization Services</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="service-details-widget">
                        <h3 class="title">Contact Us</h3>

                        <div class="row pt-20">
                            <div class="col-lg-12 col-md-4">
                                <div class="service-widget-card">
                                    <i class='bx bx-home-smile'></i>
                                    <div class="content">
                                        <h3>Address</h3>
                                        <p>147 Benmore Avenue, Cloverlea, </p>
                                        <span>Palmerston North 4412</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-4">
                                <div class="service-widget-card">
                                    <i class='bx bx-phone-call'></i>
                                    <div class="content">
                                        <h3>Phone</h3>
                                        <p><a href="tel:+6462137137">06 213 7137</a></p>
                                        <p><a href="tel:+642102662646">0210 266 2646</a></p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-4">
                                <div class="service-widget-card">
                                    <i class='bx bxs-envelope'></i>
                                    <div class="content">
                                        <h3>Email</h3>
                                        <p><a href="mailto:info&#64;pdautomotive.co.nz">info&#64;pdautomotive.co.nz</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="subscribe-area">
                        <span>SUBSCRIBE</span>
                        <h2>SUBSCRIBE FOR NEWSLETTER</h2>
                        <form class="subscribe-form">
                            <input type="email" class="form-control" placeholder="Email*" name="EMAIL">
                            <button class="default-btn" type="submit">SUBSCRIBE</button>
                        </form>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Services Details Area End -->

